import React from 'react'
import useMedia from 'use-media'
import Layout from '../../layout'
import { SEO, Action, MainHeadings, Image, Blocks } from '../../components'

import DeveloperProgramOgImage from '../../assets/images/developer-program-seo-image.png'

const BuildWithLivechat = () => {
  const isSmall = useMedia({ maxWidth: 767 })

  const imgHeight = 270

  const blocks = [
    {
      index: 1,
      title: 'Get a new revenue stream',
      description:
        'Looking for a new business opportunities for your team or willing to spin up a new income source? LiveChat Developer Program is real money.',
      image: (
        <Image
          wrapperStyles={{ height: imgHeight }}
          imgStyles={{ left: 25, top: -20 }}
          type="chartUp"
        />
      ),
    },
    {
      index: 2,
      title: 'Create meaningful apps',
      description:
        'The world doesn’t need another flashlight. The world needs tools that facilitate everyday communicate. Build software that makes customer service more enjoyable.',
      image: <Image wrapperStyles={{ height: imgHeight }} type="worldHands" />,
    },
    {
      index: 3,
      title: 'Distribute worldwide',
      description:
        'LiveChat is used by 30,000 companies from over 160 countries. Distribute your apps and solutions across the globe via the LiveChat Marketplace.',
      image: (
        <Image
          wrapperStyles={{
            bottom: 0,
            height: imgHeight,
            width: '100%',
            position: 'absolute',
          }}
          imgStyles={{ objectFit: 'cover' }}
          type="worldmap"
        />
      ),
    },
    {
      index: 4,
      title: 'Build at scale',
      description:
        '100,000 users log in to the LiveChat Agent App every day to respond to chats from millions of users. Our Chat Widget is displayed 300,000 times every minute. Build apps that work at a big scale. There are still many niches to cover!',
      image: (
        <Image
          wrapperStyles={{ height: isSmall ? imgHeight : 550, width: '100%' }}
          type="magnetMessages"
        />
      ),
      fullWidth: true,
    },
    {
      type: 'join-dev-program',
    },
    {
      index: 5,
      title: 'Focus on business',
      description:
        'We provide you with tools and support for research, development and distribution of your apps. You can focus solely on making the best product possible.',
      image: (
        <Image
          wrapperStyles={{ height: imgHeight, top: -20 }}
          imgStyles={{ height: imgHeight }}
          type="cogUp"
        />
      ),
    },
    {
      index: 6,
      title: 'We’re in this together',
      description:
        'We belive in partnerships and transparency. Our goal is for you to succeed in the LiveChat Marketplace. We’re in this together!',
      image: (
        <Image
          wrapperStyles={{ top: 30, width: 450, height: imgHeight }}
          type="mrMessage"
        />
      ),
    },
  ]

  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Why build with LiveChat?"
        description="Build communication tools with us. Join LiveChat Developer Program to grow your business and build new revenue streams."
        image={DeveloperProgramOgImage}
      />

      <div className="u-bg-black-3 u-Pb-xl md:u-Pb-2xl">
        <MainHeadings
          title={
            <>
              Why build <span className="u-text-mark">with</span> LiveChat?
            </>
          }
        />

        <Blocks items={blocks} />
      </div>

      <div className="u-bg-black2 u-Py-md">
        <Action
          image="bespokeCEO"
          title={`"LiveChat handles payments and distribution of our apps among their customers."`}
          subtitle={
            <>
              <b>Paweł Antkowiak</b>, CEO at BespokeChat
            </>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: 'Join Developer Program',
          }}
          link={{
            link: process.env.GATSBY_DOCS_URL,
            label: 'browse the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default BuildWithLivechat
